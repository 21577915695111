import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Stack, Alert, AlertTitle } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';
import { useConfirm } from "@agricircle/shared/hooks";
import { useSamplingApi } from "../../hooks/sampling";


export const SamplingStatus = ({ samplingRequest, status, onChange }) => {
    const { t } = useTranslation();
    const samplingApi = useSamplingApi();
    const confirm = useConfirm();
    const [refreshTimeout, setRefreshTimeout] = useState();

    useEffect(() => {
        if (!samplingRequest) return;
        if (status === undefined) {
            samplingApi.samplingKitStatus(samplingRequest.farmId, null, onChange);
        } else if (status?.job_id) {
            startRefreshTimeout(status.job_id, 3000);
        }

        return stopRefreshTimeout;
    }, [Boolean(samplingRequest), status?.job_id]);

    if (status == null && !samplingRequest?.area) return null;

    function handleRefresh() {
        startRefreshTimeout(status.job_id);
        samplingApi.samplingKitStatus(samplingRequest.farmId, null, onChange);
    }

    function startRefreshTimeout(jobId, timeOut) {
        const timeout = setTimeout(() => {
            samplingApi.samplingKitStatus(samplingRequest.farmId, jobId, onChange);
            setRefreshTimeout(null);
        }, timeOut || 30000);
        setRefreshTimeout(timeout);

        samplingApi.samplingKitStatus(samplingRequest.farmId, jobId, onChange);
    }

    function stopRefreshTimeout() {
        setRefreshTimeout(timeout => {
            if (timeout) {
                clearTimeout(timeout);
            }
            return null;
        });
    }

    function handleCancel(noConfirm) {
        if (noConfirm || confirm("Do you really want to cancel to background job?")) {
            stopRefreshTimeout();
            samplingApi.cancelSamplingKitRequest(samplingRequest.farmId, status.job_id, () => onChange(undefined));
        }
    }

    function handleDownload() {
        window.open(status.download_url, "_blank");
        onChange(undefined);
    }

    return (<Stack direction="row" spacing={1}>
        {(status?.job_id && !status?.error && !status?.download_url || null) && (<Alert
            severity="warning"
            action={<Stack direction="row" spacing={1}>
                <Button
                    color="error"
                    data-cy="button-wait-download-cancel"
                    onClick={handleCancel}
                    size="small"
                    endIcon={<ClearIcon />}
                >
                    {t("shared:btn-cancel")}
                </Button>
                <Button
                    color="warning"
                    data-cy="button-wait-download-ok"
                    onClick={handleRefresh}
                    size="small"
                    disabled={Boolean(refreshTimeout)}
                    endIcon={<RefreshIcon sx={!refreshTimeout ? {} : {
                        animation: "spin 5s linear infinite",
                        "@keyframes spin": {
                            "0%": {
                                transform: "rotate(0deg)",
                            },
                            "100%": {
                                transform: "rotate(360deg)",
                            },
                        },
                    }} />}
                >
                    {refreshTimeout ? "" : "Re-check status"}
                </Button>
            </Stack>}
            sx={{ width: "100%" }}
        >
            {t("sampling.request-in-progress")}
        </Alert>)}
        {(status?.job_id && status?.error || null) && (<Alert
            severity="error"
            action={<Button
                color="error"
                data-cy="button-wait-download-cancel"
                onClick={() => handleCancel(true)}
                size="small"
                endIcon={<ClearIcon />}
            >
                {t("shared:btn-clear")}
            </Button>}
            sx={{ width: "100%" }}
        >
            <AlertTitle>{t("sampling.errors.label")}</AlertTitle>
            {status.error}
        </Alert>)}
        {(!status?.error && status?.download_url || null) && (<Alert
            severity="success"
            action={<Stack direction="row" spacing={1}>
                <Button
                    color="error"
                    data-cy="button-wait-download-cancel"
                    onClick={handleCancel}
                    size="small"
                    endIcon={<ClearIcon />}
                >
                    {t("shared:btn-cancel")}
                </Button>
                <Button
                    color="success"
                    onClick={handleDownload}
                    data-cy="button-wait-download-ok"
                    endIcon={<DownloadIcon />}
                    size="small"
                >
                    {t("sampling.request-download-btn")}
                </Button>
            </Stack>}
            sx={{ width: "100%" }}
        >
            {t("sampling.request-ready")}
        </Alert>)}
    </Stack >);
};
