import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Box, Tooltip } from "@mui/material";
import { displayInfoMessage, displayErrorMessage } from "@agricircle/shared/redux";
import { useModal } from "@agricircle/shared/hooks";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { useFarmApi } from "@agricircle/shared/farms/hooks";
import { FieldsSelector } from "@agricircle/shared/farms/components";
import { Subheading } from "@agricircle/shared/widgets";
import { SamplingStatus } from "./status";
import { RequestSoilSamplingModal, ImportMeasurementsModal } from "./sampling";
import { useSamplingApi } from "../../hooks/sampling";


export const SamplingPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const farm = useSelector(selectFarm);
    const samplingApi = useSamplingApi();
    const farmApi = useFarmApi();
    const [fields, setFields] = useState(null);
    const [samplingRequest, setSamplingRequest] = useState();
    const [samplingRequestStatus, setSamplingRequestStatus] = useState();

    useEffect(() => {
        if (!farm) return;

        setFields(null);
        setSamplingRequest({ farmId: farm.id, area: 0, fieldIds: [] });
        farmApi.listFields(farm.id, { extraAttributes: ["sentinel", "soil"] }, setFields);
    }, [farm?.id]);

    if (!farm) return null;

    function handleLabResultsImport() {
        openModal(
            null,
            <ImportMeasurementsModal />,
            handleMeasurements
        );
    }

    function handleMeasurements(formData) {
        samplingApi.parseXlsxMeasurementsFile(
            formData,
            measurements => samplingApi.uploadMeasurements(measurements, () => dispatch(displayInfoMessage(t("measurements-imported"))))
        );
    }

    function onChangeFieldSelection(fieldIds) {
        const newSelection = { farmId: farm.id, area: 0, fieldIds: [] };
        if (fieldIds.length > 0) {
            let unSelectable = false;
            fields.forEach(f => {
                if (fieldIds.includes(f.id)) {
                    newSelection.fieldIds.push(f.id);
                    newSelection.area += f.area;
                }
            });
            if (unSelectable && fieldIds.length == 1)
                dispatch(displayErrorMessage(t("sampling.errors.no-info")));
        }
        setSamplingRequest(newSelection);
        return newSelection.fieldIds;
    }

    function handlePrepareSamplingKit(e) {
        if (e.shiftKey) {
            confirm(
                t("sampling.measurements-regenerate"),
                () => {
                    samplingApi.regenerateSamplingKit(samplingRequest.farmId, samplingRequest.fieldIds, onChange);
                }
            );
        } else {
            openModal(
                null,
                <RequestSoilSamplingModal />,
                (measurements, depths) => {
                    samplingApi.prepareSamplingKit(samplingRequest.farmId,
                        samplingRequest.fieldIds,
                        measurements,
                        depths,
                        setSamplingRequestStatus
                    );
                },
                { samplingRequest }
            );
        }
    }

    const headers = [t("shared:farms.field-name-label"), t("shared:farms.area-label")];
    const actions = (f) => (<>
        <SentinelStatus sentinel={f.sentinel} />
        <SoilStatus soil={f.soil} icon="fa-route" property="sampling_path" />
    </>);

    return (<Box sx={{ padding: 1 }}>
        <Subheading title={t("sampling.title")}>
            {(samplingRequest?.area || samplingRequestStatus == null)
                ? <Button
                    variant="contained"
                    data-cy="button-prepare-sampling-kit"
                    onClick={handlePrepareSamplingKit}
                    size="small"
                >
                    {t("sampling.request-btn")}
                </Button>
                : <Button
                    variant="contained"
                    data-cy="button-upload-lab-results"
                    onClick={handleLabResultsImport}
                    color="primary"
                    size="small"
                >
                    {t("sampling.measurements-upload-btn")}
                </Button>
            }
        </Subheading>

        {(samplingRequestStatus !== null || null) && (<SamplingStatus
            samplingRequest={samplingRequest}
            status={samplingRequestStatus}
            onChange={setSamplingRequestStatus}
        />)}

        {(samplingRequestStatus === "") && (<FieldsSelector
            fields={fields}
            headers={headers}
            actions={actions}
            selected={samplingRequest?.fieldIds || []}
            onChange={onChangeFieldSelection}
        />)}
    </Box>);
};

const SentinelStatus = ({ sentinel }) => {
    let tip;
    const style = { marginLeft: 2 };
    if (!sentinel?.status) {
        style.color = "lightgray";
        tip = "no data yet";
    } else if (sentinel.status === "in_progress") {
        style.color = "gold";
        tip = "in progress";
    } else if (sentinel.error) {
        style.color = "red";
        tip = "failed to download satellite data";
    } else if (!sentinel.date) {
        style.color = "lightgray";
        style.textDecoration = 'line-through black double';
        tip = "not satellite data available";
    } else {
        style.color = "ForestGreen";
    }
    const status = <span className="fa fa-satellite" style={style} />;
    if (tip) return <Tooltip title={tip}>{status}</Tooltip>;
    return status;
};


const SoilStatus = ({ soil, property, icon }) => {
    let tip;
    const style = { marginLeft: 2 };
    if (property === "sampling_path" && soil?.sampling_path) {
        style.color = "ForestGreen";
    } else if (!soil?.status || soil?.recalculate) {
        style.color = "lightgray";
        tip = soil?.status ? "stale data" : "no data yet";
    }
    else if (soil.status === "in_progress") {
        style.color = "gold";
        tip = "in progress";
    } else if (soil.error) {
        style.color = "red";
        tip = soil.error;
    } else if (!soil.properties?.length || property && !soil.properties.includes(property)) {
        style.color = "lightgray";
        style.textDecoration = 'line-through black double';
        tip = "not available";
    } else {
        style.color = "ForestGreen";
    }
    const status = <span className={`fa ${icon || "fa-signal"}`} style={style} />;
    if (tip) return <Tooltip title={tip}>{status}</Tooltip>;
    return status;
};
