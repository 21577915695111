import { useNavigate, useLocation } from "react-router-dom";
import { Grid2 as Grid, IconButton, Typography } from "@mui/material";

export const Subheading = ({ title, pop, children, properties, ...rest }) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (<Grid container alignItems="center" justifyContent="space-between" data-cy="subheading">
        <Grid>
            <IconButton
                {...rest}
                onClick={() => {
                    if (pop !== 0) {
                        const sliceIndex = pop ? -pop : -1;
                        const pathElements = location.pathname.split("/").slice(0, sliceIndex);
                        navigate(pathElements.join("/") || "/", { replace: true });
                    }
                }}
                title={pop === 0 ? "" : "back"}
                className={pop === 0 ? "" : "fa-solid fa-circle-arrow-left"}
                color="info">
                <Typography sx={{ display: "inline-block", fontSize: "100%", marginLeft: 1 }}>{title}</Typography>
            </IconButton>
        </Grid>
        <Grid>{children}</Grid>
    </Grid>);
};
