import { Table, TableHead, TableBody, TableRow, TableCell, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FieldsSelector = ({ fields, headers, info, actions, selected, onChange }) => {
    const { t } = useTranslation();
    const widths = ["5%", "55%", "10%", `${info ? 15 : 0}%`, `${info ? 15 : 30}%`];

    function updateAllSelection(e) {
        let newFields;
        if (selected.length > 0)
            newFields = [];
        else
            newFields = fields.map((f) => f.id);
        onChange(newFields);
    }

    function updateSelection(e, f) {
        let newFields;
        if (e.target.checked) {
            newFields = [...selected, f.id];
        } else {
            const idx = selected.indexOf(f.id);
            newFields = [...selected.slice(0, idx), ...selected.slice(idx + 1)];
        }
        onChange(newFields);
    }

    return fields == null
        ? <i>{t("shared:loading")}</i>
        : (<Table data-cy="table-fields">
            <TableHead><TableRow>
                <TableCell width={widths[0]} align="center">
                    <Checkbox
                        data-cy="checkbox-all-fields"
                        checked={selected?.length > 0 && selected?.length === fields.length}
                        onChange={updateAllSelection} />
                </TableCell>
                <TableCell width={widths[1]}>{headers[0]}</TableCell>
                <TableCell width={widths[2]} align="right">{headers[1] || null}</TableCell>
                <TableCell width={widths[3]}>{(info || null) && (headers[2] || null)}</TableCell>
                <TableCell width={widths[4]} align="right">{headers[3] || null}</TableCell>
            </TableRow></TableHead>
            <TableBody>
                {(!fields.length || null) && <TableRow>
                    <TableCell colSpan={5}>{t("shared:farms.no-fields")}</TableCell>
                </TableRow>}
                {(fields.length || null) && fields.map(f => (<TableRow key={f.id}>
                    <TableCell align="center">
                        <Checkbox
                            checked={selected?.includes(f.id)}
                            onChange={(e) => updateSelection(e, f)} />
                    </TableCell>
                    <TableCell>{f.name}</TableCell>
                    <TableCell align="right">{(headers[1] || null) && f.area?.toFixed(1) || 0}</TableCell>
                    <TableCell>{info && info(f)}</TableCell>
                    <TableCell align="right">{actions && actions(f)}</TableCell>
                </TableRow>))}
            </TableBody>
        </Table>);
};
