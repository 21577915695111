import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Stack, Typography, TextField, MenuItem, IconButton, Alert, Grid2 as Grid, Switch, FormControlLabel, Tooltip } from "@mui/material";
import RecomputeIcon from "@mui/icons-material/Refresh";
import { FarmMap } from "@agricircle/shared/farms/components";
import { CopyRefButton } from "@agricircle/shared/widgets";
import { useAuthContext } from "@agricircle/shared";
import { displayInfoMessage } from "@agricircle/shared/redux";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { apiUrl, usePrompt } from "@agricircle/shared/hooks";
import { useSoilApi } from "../../hooks/soil";
import { LAYERS } from "./layers";

const COLOR_MAP = {
    undef: { weight: 0.5, color: "darkgray", fillOpacity: 0.5, fillColor: "darkgray", dashArray: "3, 3" },
    error: { weight: 0.5, color: "red", fillOpacity: 0.35, fillColor: "red", dashArray: "" },
    in_progress: { weight: 0.5, color: "yellow", fillOpacity: 0.35, fillColor: "yellow", dashArray: "" },
    available: { weight: 0.5, color: "blue", fillOpacity: 0.15, fillColor: "blue", dashArray: "" },
    error_undef: { weight: 0.5, color: "red", fillOpacity: 0.5, fillColor: "darkgray", dashArray: "3, 3" },
    error_stale: { weight: 0.5, color: "orange", fillOpacity: 0.5, fillColor: "red", dashArray: "3, 3" },
    available_undef: { weight: 0.5, color: "darkgray", fillOpacity: 0.5, fillColor: "darkgray", dashArray: "3, 3" },
    available_stale: { weight: 0.5, color: "orange", fillOpacity: 0.5, fillColor: "orange", dashArray: "3, 3" }
};

function fieldStatus(field, layer) {
    if (!field || !layer) return "undef";

    if (layer.type === "soil/properties") {
        let status = field.soil?.error
            ? "error"
            : field.soil?.status;
        if (status !== "in_progress") {
            if (field.soil?.recalculate)
                status = (status || "in_progress") + "_stale";
            else if (!status || !field.soil.properties?.includes(layer.id))
                status = "undef";
        }
        return status;
    }
    return "available";
}


export default () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { profile } = useAuthContext();
    const prompt = usePrompt();
    const soilApi = useSoilApi();
    const farm = useSelector(selectFarm);
    const fields = farm?.fields;
    const selectedFields = useRef(null);
    const layerRef = useRef();
    const showPhysicalValuesRef = useRef(false);
    const [_, setForceUpdate] = useState(0);
    const [fieldOptions, setFieldOptions] = useState();

    useEffect(() => {
        if (!fields || fieldOptions) return;

        const options = {};
        fields.forEach(field => options[field.id] = COLOR_MAP[fieldStatus(field, layerRef.current)]);
        setFieldOptions(options);
    }, [Boolean(fields), Boolean(fieldOptions)]);

    useEffect(() => {
        selectedFields.current = null;
        setForceUpdate(forceUpdate => forceUpdate + 1);
    }, [farm?.id]);

    const userLayers = useMemo(() => {
        if (!profile?.organization) return [];
        return layersFromPermissions(profile.organization);
    }, [Boolean(profile?.organization)]);

    const availableSoilProperties = useMemo(() => {
        const properties = new Set();
        if (farm?.id && fields) {
            fields.forEach(f => {
                if (f.soil?.properties)
                    f.soil.properties.forEach(p => properties.add(p));
            });
        }
        return properties;
    }, [farm?.id, Boolean(fields)]);

    if (!fields) return null;

    function handleFieldClick(fieldId) {
        const selected = selectedFields.current?.[0]?.field;
        if (!fieldId && !selected || fieldId === selected?.id) {
            return false;
        }
        const field = fieldId && fields.find(f => f.id === fieldId);
        selectedFields.current = fieldId ? [{ field }] : null;
        loadLayerMeta();
        return false;
    }

    async function loadLayerMeta() {
        const layer = layerRef.current;
        if (!selectedFields.current || !layer) {
            setFieldOptions(null);
            return;
        }

        const showPhysicalValues = layer.physical_info && showPhysicalValuesRef.current;

        for (const selected of selectedFields.current) {
            if (!selected.meta && layer.type !== "yield" && selectedFields.current.length === 1) {
                if (layer.type === "soil/properties") {
                    if (selected.field.soil?.properties?.includes(layer.id)) {
                        await soilApi.getSoilPropertyMeta(selected.field.id, layer.id, (m) => {
                            if (m?.meta) {
                                selected.meta = m;
                            }
                        });
                    }
                }
            } else if (layer.type == "yield") {
                await soilApi.getYieldPerformanceMeta(selected.field.id, (m) => {
                    selected.meta = {
                        meta: {}
                    };
                    if (m?.no_crop_cycle)
                        selected.meta[t("label-no-crop-cycle")] = "";
                    if (m?.error)
                        selected.meta["Error"] = m.error;
                });
            }
            if (selected.meta) {
                const overlayUrl = layer.type == "soil/properties" && !selected.field.soil?.properties?.includes(layer.id)
                    ? ""
                    : layer.type == "yield"
                        ? (selected.meta?.date && !selected.meta?.no_crop_cycle) ? `${apiUrl}/fields/${selected.field.id}/${layer.type}/${layer.id}.png` : ""
                        : `${apiUrl}/fields/${selected.field.id}/${layer.type}/${layer.id}.png?${layer.hasClasses && !showPhysicalValues ? "classes" : ""}`;
                const mapBbox = selected.field.map_metadata.map_bbox;
                const bounds = [[mapBbox[0][1], mapBbox[0][0]], [mapBbox[1][1], mapBbox[1][0]]];
                selected.overlay = { bounds, url: overlayUrl };
            } else {
                selected.overlay = null;
            }
        }
        setFieldOptions(null);
    }

    async function handleLayerChange(e) {
        const layerId = e.target.value;
        if (layerId === layerRef.current?.id) return;

        layerRef.current = userLayers.find(l => l.id === layerId);
        if (selectedFields.current) {
            for (const selected of selectedFields.current) {
                delete selected.meta;
            }
            loadLayerMeta();
        } else {
            setFieldOptions(null);
        }
    }

    function handlePhysicalSwitch() {
        showPhysicalValuesRef.current = !showPhysicalValuesRef.current;
        loadLayerMeta();
    }

    function handleUpdateFieldLayerType() {
        const field = selectedFields.current[0].field;
        const needConfirmation = (layerRef.current.type === "soil/properties" && !field.soil?.properties)
            || (layerRef.current.type === "yield");
        if (needConfirmation) {
            const validator = (v) => (v === t("shared:confirm-billing-prompt")) ? null : "";
            prompt(
                t("shared:billable-operation"),
                () => handleCompute(field),
                { label: t("shared:confirm-billing-text", { "count": 1, "area": field.area }), action: t("shared:btn-proceed"), validator }
            );
        } else {
            handleCompute(field);
        }
    }

    function handleCompute(field) {
        if (layerRef.current.type === "soil/properties") {
            soilApi.computeSoil(field.id, async (result) => {
                await loadLayerMeta();
                if (!result.completed_at) {
                    dispatch(displayInfoMessage(t("text-update-in-progress")));
                }
            });
        } else if (layerRef.current.type === "yield") {
            soilApi.computeYieldPerformance(field.id, async (result) => {
                await loadLayerMeta();
                if (!result.completed_at) {
                    dispatch(displayInfoMessage(t("text-update-in-progress")));
                }
            });
        }
    }

    const singleSelected = selectedFields.current?.length === 1 ? selectedFields.current[0] : null;
    const updateFieldLayerType = singleSelected && isUpdatableField(singleSelected.field, layerRef.current, profile);

    return (<Stack direction="row" sx={{ width: "100%" }}>
        <FarmMap
            farm={farm}
            fields={fields}
            fieldOptions={fieldOptions}
            selectedFields={selectedFields.current ? selectedFields.current.map(f => f.field.id) : null}
            onFieldClick={handleFieldClick}
            fieldOverlays={selectedFields.current ? selectedFields.current.filter(f => Boolean(f.overlay)).map(f => f.overlay) : null}
        />
        <Stack sx={{ width: "400px", backgroundColor: "#fffad350", padding: "10px" }} spacing={1}>
            <Typography variant="h5">{t("title-inspect")}</Typography>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
                {(userLayers?.length || null) && (<TextField
                    value={layerRef.current?.id || "-"}
                    onChange={handleLayerChange}
                    select
                    margin="dense"
                    variant="standard"
                    data-cy="layer-selector"
                >
                    <MenuItem disabled={true} value="-">{t("label-layer-select")}</MenuItem>
                    {userLayers
                        .filter(l => l.type !== "soil/properties" || availableSoilProperties.has(l.id))
                        .map(l => <MenuItem key={l.id} value={l.id}>{t(`label-layer-${l.id}`)}</MenuItem>)}
                </TextField>)}
                {(updateFieldLayerType !== null || null) && (<IconButton
                    data-cy="button-recompute"
                    title={t("label-update")}
                    size="small"
                    color="primary"
                    onClick={handleUpdateFieldLayerType}
                    disabled={Boolean(updateFieldLayerType)}
                >
                    <RecomputeIcon />
                </IconButton>)}
            </Stack>
            <FieldInfo
                selectedFields={selectedFields.current}
                layer={layerRef.current}
                showPhysicalValues={layerRef.current?.physical_info && showPhysicalValuesRef.current}
                sx={{ width: "400px" }}
            >
                {(selectedFields.current?.length === 1 && layerRef.current?.info && layerRef.current?.physical_info || null) && <FormControlLabel
                    control={<Switch
                        size="small"
                        checked={showPhysicalValuesRef.current}
                        onChange={handlePhysicalSwitch}
                    />}
                    label={t("label-physical")}
                />}
            </FieldInfo>
        </Stack>
    </Stack>);
};

const FieldInfo = ({ selectedFields, layer, showPhysicalValues, children }) => {
    const { t } = useTranslation();

    const selected = selectedFields?.[0];
    const status = selected && fieldStatus(selected.field);

    return (<Stack spacing={1}>
        <Stack direction="row" alignItems="center">
            {selected?.field
                ? (<Tooltip
                    title={<span><CopyRefButton info={selected.field.id} />{selected.field.id}</span>}
                    placement="bottom-start"
                    enterDelay={1000}
                    leaveDelay={1000}
                    arrow
                    slotProps={{
                        popper: {
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: { offset: [0, -14] },
                                }
                            ]
                        }
                    }}
                >
                    <Typography noWrap variant="h6" data-cy="selected-field">{selected.field.name}</Typography>
                </Tooltip>)
                : <Typography noWrap variant="h6" data-cy="selected-field">{t("label-select-field")}</Typography>}
        </Stack>
        {(status === "in_progress" || null) && (<Alert severity="info">
            <Typography>{t("text-soil-status-in-progress")}</Typography>
        </Alert>)}
        {(status === "error" || null) && (<Alert severity="error">
            {t("text-soil-status-error")} {selected.field.soil.error || t("errors.unknown")}
        </Alert>)}
        {children}
        {(selected && layer?.info || null) && ((layer.type === "soil/properties" && selected?.field?.soil?.recalculate)
            ? (<Alert severity="warning">
                {t("label-needs-update")}
            </Alert>)
            : (<LayerInfo
                info={showPhysicalValues ? layer?.physical_info : layer.info} meta={selected.meta}
                data-cy="map-field-info"
                sx={{ width: 300, marginTop: 1 }}
            >
                {(layer.id == "performance" || null) && <Stack align="center">
                    {(selected.meta?.error || null) && <Alert severity="error">{selected.meta?.error}</Alert>}
                    {(selected.meta?.no_crop_cycle || null) && <small>{t("label-no-crop-cycle")}</small>}
                </Stack>}
            </LayerInfo>)
        )}
    </Stack>);
};

const LayerInfo = ({ info, meta, children, ...rest }) => {
    const { t } = useTranslation();

    return (<Stack spacing={1} {...rest}>
        {(!meta?.meta && info.units !== null || null) && (<Alert severity="info">
            {t("label-no-data")}
        </Alert>)}
        {(meta?.meta && Object.keys(meta).length && Object.keys(meta).length > 1 || null) && (<Stack>
            {Object.keys(meta).map(m => m === "meta"
                ? null
                : (<Stack key={`meta${m}`} direction="row" alignItems="center" justifyContent="space-between">
                    <span>{m}</span>
                    <span>{formatMeta(meta[m])}</span>
                </Stack>)
            )}
        </Stack>)}
        {(meta?.meta && Object.keys(meta.meta).length || null) && (<Stack component="fieldset">
            {(info.units || null) && <legend>{info.units}</legend>}
            {Object.keys(meta.meta).map(m => (<Stack key={`meta_meta${m}`} direction="row" alignItems="center" justifyContent="space-between">
                <span>{m}</span>
                <span>{formatMeta(meta.meta[m], (m.startsWith("bd_") || m.endsWith("%")) ? 1 : info.unit_conversion_factor || 1)}</span>
            </Stack>))}
        </Stack>)}
        {((meta?.meta || info.units === null) && info.ranges?.length || null) && (<Stack spacing={1} sx={{ marginTop: 1 }}>
            {info.ranges.map((r, idx) => <LegendItem
                key={`l-${idx}`}
                min={r.min}
                max={r.max}
                colorMin={r.colorMin}
                colorMax={r.colorMax}
            />)}
        </Stack>)}
        {children}
    </Stack>);
};

function formatMeta(value, factor) {
    if (typeof value === "string") return value;
    if (typeof value === "boolean") return value ? "yes" : "no";
    let roundDecimals = 0;
    value /= factor;
    if (value < 10)
        roundDecimals = 2;
    else if (value < 100)
        roundDecimals = 1;
    return ((value * 100) / 100).toFixed(roundDecimals);
}

const LegendItem = ({ min, max, colorMin, colorMax, ...rest }) => {
    return (<Grid container {...rest}
        justifyContent={max ? "space-between" : "center"}
        sx={{ height: 40, padding: 1, color: "#f0f0f0", fontWeight: "bold", textShadow: "-1px -1px 0 #555, 1px -1px 0 #555, -1px 1px 0 #555, 1px 1px 0 #555", fontSize: 16, verticalAlign: "middle", paddingTop: 1.25 }}
        style={{ background: `linear-gradient(to right, ${colorMin}, ${colorMax || colorMin})` }}
    >
        <Grid>{min}</Grid>
        {(max || null) && <Grid>. . .</Grid>}
        {(max || null) && <Grid>{max}</Grid>}
    </Grid>);
};


function layersFromPermissions(organization) {
    const permissions = new Set([...organization.permissions, ...organization.default_permissions, ...organization.custom_permissions]);
    const layers = LAYERS.filter(l => {
        if (l.type == "soil/properties") return permissions.has(`api_soil_${l.id}`);
        if (l.type == "yield") return permissions.has("satellite_compute_yield_performance");
        return false;
    });
    return layers;
}

function isUpdatableField(field, layer, profile) {
    if (!field || !layer || !profile?.id) return null;

    if (layer.type === "soil/properties") {
        if (field.soil?.status === "in_progress") return null;
        if (!field.soil?.properties || field.soil?.recalculate || field.soil?.error) return "";
    } else if (layer.type === "yield") {
        return "";
    }
    return null;
}
