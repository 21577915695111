import { useRef, useEffect, useState } from "react";
import { Grid2 as Grid, Stack, Box, Button, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, TextField, Typography, Tooltip, RadioGroup, Radio } from "@mui/material";
import styled from "styled-components";
import BackupIcon from "@mui/icons-material/Backup";
import { useModal, useValidation } from "@agricircle/shared/hooks";
import { useSamplingApi } from "../../hooks/sampling";
import { useTranslation } from "react-i18next";


export const RequestSoilSamplingModal = () => {
    const { closeModal, onClose, options: { samplingRequest } } = useModal();
    const { t } = useTranslation();
    const samplingApi = useSamplingApi();
    const [info, setInfo] = useState();
    const [form, setForm] = useState();
    const VALIDATION_OPTIONS = {
        confirmation: { validator: (c) => ((c === null || c === t("shared:confirm-billing-prompt")) ? null : "") },
        selected: { validator: (s) => (s.size > 0 ? null : t("sampling.errors.no-measurements")) },
    };
    const validation = useValidation(VALIDATION_OPTIONS);

    useEffect(() => {
        async function fetchData() {
            let result = await samplingApi.samplingKitPreflight(samplingRequest.farmId, samplingRequest.fieldIds);
            if (result.isError) {
                setForm({});
                return;
            }

            const area = result.data?.area || 0;
            const count = result.data?.count || 0;
            result = await samplingApi.getMeasurementsInfo();
            if (!result.isError) {
                const required = result.data.filter(m => m.id != "bulk_density" && m.required).map(m => m.id);
                const additional = result.data.filter(m => m.id != "bulk_density" && !m.required).map(m => m.id);
                const measurements = result.data.reduce((measurements, m) => Object.assign(
                    measurements,
                    { [m.id]: { ...m, selected: Boolean(m.required) } }),
                    {}
                );
                const selected = new Set();
                result.data.forEach(m => m.required && selected.add(m.id));
                setInfo({
                    area,
                    count,
                    measurements,
                    required: required.length > 0 ? required : null,
                    additional: additional.length > 0 ? additional : null
                });
                setForm({
                    confirmation: area ? "" : null,
                    selected,
                    depths: [30]
                });
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!form) return;
        validation.init(form);
    }, [Boolean(form)]);

    if (!form) return null;

    function handleConfirmationChange(e) {
        const value = validation.validate("confirmation", e.target.value);
        if (!value) return;
        setForm({ ...form, confirmation: value });
    }

    function handleChangeMeasurement(e) {
        const measurementId = e.target.name;
        const newSelected = new Set([...form.selected]);
        if (e.target.checked)
            newSelected.add(measurementId);
        else {
            newSelected.delete(measurementId);
            if (!newSelected.has("humus") && !newSelected.has("soc")) {
                newSelected.delete("bulk_density");
            }
        }
        validation.validate("selected", newSelected);
        setForm({
            confirmation: form.confirmation,
            selected: newSelected
        });
    }

    function handleChangeDepths(e, v) {
        setForm({ ...form, depths: v === 'true' ? [30, 60] : [30] });
    }

    function handleGenerate() {
        const hasBulkDensity = form.selected.has("bulk_density");
        closeModal();
        onClose(Array.from(form.selected), hasBulkDensity ? form.depths : [30]);
    }

    return (<>
        <DialogTitle>{t("sampling.prepare-title")}</DialogTitle>
        <DialogContent dividers>
            {info
                ? (<Grid container>
                    <Grid size={6}>
                        <Typography variant="subtitle2">{t("sampling.measurements-required")}</Typography>
                        {!info.required
                            ? "none"
                            : (<FormGroup>
                                {info.required.map(m => <MeasurementRequest
                                    key={m}
                                    measurement={info.measurements[m]}
                                    value={form.selected.has(m)}
                                    onChange={handleChangeMeasurement}
                                />)}
                            </FormGroup>)
                        }
                    </Grid>
                    <Grid size={6}>
                        <Typography variant="subtitle2">{t("sampling.measurements-optional")}</Typography>
                        {!info.additional
                            ? "none"
                            : (<FormGroup>
                                {info.additional.map(m => <MeasurementRequest
                                    key={m}
                                    measurement={info.measurements[m]}
                                    value={form.selected.has(m)}
                                    onChange={handleChangeMeasurement}
                                />)}
                            </FormGroup>)
                        }
                    </Grid>
                    {(form.selected.has("soc") || form.selected.has("humus") || null) && (<>
                        <Grid size={4} sx={{ marginTop: 1 }}>
                            <MeasurementRequest
                                key="bulk_density"
                                measurement={info.measurements.bulk_density}
                                value={form.selected.has("bulk_density")}
                                onChange={handleChangeMeasurement}
                            />
                        </Grid>
                        <Grid size={8}>
                            {(form.selected.has("bulk_density") || null) && <RadioGroup
                                name="horizons"
                                value={form.depths?.length == 2}
                                row
                                onChange={handleChangeDepths}
                            >
                                <FormControlLabel value="false" control={<Radio />} label="30cm only" />
                                <FormControlLabel value="true" control={<Radio />} label="30cm and 60cm" />
                            </RadioGroup>}
                        </Grid>
                    </>)}
                </Grid>)
                : "An error occured"
            }
        </DialogContent>
        <DialogActions sx={{ flexDirection: "column", alignItems: "flex-start" }}>
            {(info?.area || null) && (<Typography>
                {t("shared:confirm-billing-text", { area: info.area.toFixed(1), count: info.count })}
            </Typography>)}
            <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ width: "100%", paddingRight: "15px" }}>
                {(info?.area || null) && (<TextField
                    value={form.confirmation}
                    onChange={handleConfirmationChange}
                    error={!validation.isValid("confirmation")}
                    helperText={validation.error("confirmation")}
                    placeholder={t("shared:confirm-billing-prompt")}
                    margin="dense"
                    variant="standard"
                    autoComplete="off"
                    sx={{ width: "150px", marginRight: "auto !important" }}
                />)}
                <Button
                    variant="outlined"
                    onClick={closeModal}
                    data-cy="button-measurements-cancel"
                >
                    {t("shared:btn-cancel")}
                </Button>
                {(info || null) && (<Button
                    variant="contained"
                    onClick={handleGenerate}
                    data-cy="button-measurements-ok"
                    disabled={!validation.isValid()}
                >
                    {t("sampling.prepare-btn")}
                </Button>)}
            </Stack>
        </DialogActions>
    </>);
};

const MeasurementRequest = ({ measurement: m, value, onChange }) => (<Tooltip title={<span dangerouslySetInnerHTML={{ __html: m.description }} />}>
    <FormControlLabel
        control={<Checkbox size="small" sx={{ padding: 0 }} checked={value}
            name={m.id}
            onChange={onChange}
        />}
        label={<span dangerouslySetInnerHTML={{ __html: m.name }} />}
    />
</Tooltip>);

const FilesInput = styled.input`
  display: none;
`;

export const ImportMeasurementsModal = () => {
    const { closeModal, onClose } = useModal();
    const { t } = useTranslation();
    const [measurementFile, setMeasurementFile] = useState(null);
    const inputFile = useRef(null);

    useEffect(() => setTimeout(() => inputFile.current.click(), 100) && undefined, []);

    function handleFileChange(event) {
        setMeasurementFile(event.currentTarget.files[0]);
    }

    function handleCancel() {
        closeModal();
    }

    function handleConfirm() {
        const formData = new FormData();
        formData.append("file", measurementFile);
        closeModal();
        onClose(formData);
    }

    const UploadButton = () => (<label>
        <Button
            variant={measurementFile ? "outlined" : "contained"}
            size="small"
            component="span"
            startIcon={<BackupIcon />}>
            {t("sampling.measurements-select-btn")}
        </Button>
        <FilesInput accept=".xlsx,.csv" type="file" ref={inputFile} onChange={handleFileChange} />
    </label>);

    return (<>
        <DialogTitle>{t("sampling.measurements-import")}</DialogTitle>
        <DialogContent dividers>
            <Grid container>
                <Grid size={4}><UploadButton /></Grid>
                <Grid size={8}>
                    <strong>{t("sampling.measurements-select-file")}</strong>
                    {measurementFile && <Box sx={{ fontSize: "75%" }}>{measurementFile.name}</Box>}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                variant="outlined"
                data-cy="button-import-cancel"
                onClick={handleCancel}
            >
                {t("shared:btn-cancel")}
            </Button>
            <Button
                variant="contained"
                data-cy="button-import-ok"
                disabled={!Boolean(measurementFile)}
                onClick={handleConfirm}
            >
                {t("sampling.measurements-import-btn")}
            </Button>
        </DialogActions>
    </>);
};
