import { useState, useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton, Alert, Snackbar, Drawer, List, ListItem, ListItemText, ListItemIcon, ListItemButton, Divider, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutIcon from "@mui/icons-material/Logout";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FarmSelector } from "@agricircle/shared/farms/components";
import { SamplingPage } from "./components/sampling";
import Product from "./components/Product";
import Inspect from "./components/inspect";
import { useAuthContext, LoadingProvider } from "@agricircle/shared";
import { selectNextMessage, popMessage } from "@agricircle/shared/redux";
import { useAsyncApi, apiUrl, deserializeJsonapiEntity, ModalProvider } from "@agricircle/shared/hooks";
import { LanguageSelector, AppContainer, AppToolBar } from "@agricircle/shared/widgets";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { loadSoil } from "./hooks/load";
import { store } from "./redux/store";
import { setMode, selectMode } from "./redux/soil";


const DRAWER_WIDTH = 350;


const SoilMenu = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const mode = useSelector(selectMode);
    const farm = useSelector(selectFarm);
    const { profile } = useAuthContext();

    function handleLogout() {
        if (profile.impersonated_by) {
            const rootDomain = (new URL(apiUrl)).hostname;
            window.location.href = `${apiUrl}/auth/impersonate/stop?after_logout=https://${rootDomain}/tools/admin`;
        } else {
            window.location.href = `${apiUrl}/auth/logout?after_logout=https://${window.location.host}/`;
        }
    }

    function handleToggleMode() {
        onClose();
        dispatch(setMode(mode === "edit" ? "work" : "edit"));
    }

    return (<List dense>
        <ListItem secondaryAction={<IconButton data-cy="menu-close" onClick={onClose}><CloseIcon /></IconButton>}>
            <ListItemText primary={<b>{t("title-app-name")}</b>} />
        </ListItem>
        <ListItem>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <FarmSelector onChange={onClose} canCreate={mode === "edit"} />
        </ListItem>
        <ListItem>
            <ListItemIcon>{mode === "edit" ? <ModeEditIcon /> : <MapOutlinedIcon />}</ListItemIcon>
            <ListItemText primary={t("shared:title-edit-mode")} />
            <Switch
                data-cy="menu-mode"
                edge="end"
                onChange={handleToggleMode}
                checked={mode === "edit"}
            />
        </ListItem>
        {(mode === "work" || null) && <ListItemButton
            disabled={!farm?.id}
            href="/sampling"
            data-cy="menu-sampling"
        >
            <ListItemIcon><ScienceOutlinedIcon /></ListItemIcon>
            <ListItemText primary={t("sampling.title")} />
        </ListItemButton>}
        <Divider sx={{ margin: "10px 0 10px 0" }} />
        <ListItemButton onClick={handleLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary={t(profile.impersonated_by ? "shared:title-stop-impersonation" : "shared:title-logout")} secondary={profile.name} />
        </ListItemButton>
        <ListItem data-cy="menu-language">
            <ListItemIcon><LanguageIcon /></ListItemIcon>
            <LanguageSelector />
        </ListItem>
    </List>);
};

const SoilAppBar = () => {
    const { t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (<div>
        <AppToolBar title={t("title-app-name")} onDrawer={handleDrawerToggle} />
        <Drawer
            container={window.document.body}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: { xs: "100%", sm: DRAWER_WIDTH } } }}
        >
            <SoilMenu onClose={handleDrawerToggle} />
        </Drawer>
    </div>);
};

const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();
    const { message, isErrorMessage } = useSelector(selectNextMessage);
    const [delayed, setDelayed] = useState(true);
    const { profile, setProfile } = useAuthContext();

    function onCloseMessage() {
        dispatch(popMessage());
    }

    useEffect(() => {
        if (profile?.id) return;
        const loadProfile = async () => {
            const result = await sendRequest("GET", "users/myself", null, null, true);
            if (result.status > 300)
                setProfile({ status: result.status });
            else
                setProfile(deserializeJsonapiEntity(result.data));
        };

        loadProfile();
        const timeout = setTimeout(() => setDelayed(false), 500);
        return () => clearTimeout(timeout);
    }, [profile?.id]);

    loadSoil();

    if (profile == null) return delayed ? null : t("shared:loading");

    const unauthorized = profile.id && !profile.organization?.roles?.includes("soil");
    if (unauthorized || !profile.id || window.location.href.endsWith("product")) {
        return <Product />;
    }

    return (<div>
        <ModalProvider>
            <BrowserRouter basename="/">
                <AppContainer noMaxWidth>
                    <Snackbar open={Boolean(message)} onClose={onCloseMessage} autoHideDuration={4000}>
                        <Alert onClose={onCloseMessage} severity={isErrorMessage ? "error" : "success"} style={{ width: "100%" }}>{message}</Alert>
                    </Snackbar>
                    <div style={{ display: "flex" }}>
                        <Routes>
                            <Route path="/sampling" element={<div style={{ width: "100%" }}><SoilAppBar /><SamplingPage /></div>} />
                            <Route path="/" element={<div style={{ width: "100%" }}><SoilAppBar /><Inspect /></div>} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </div>
                </AppContainer>
            </BrowserRouter>
        </ModalProvider>
    </div>);
};

export default () => (<Provider store={store}>
    <LoadingProvider>
        <App />
    </LoadingProvider>
</Provider>);
