import { useDispatch } from "react-redux";
import { displayErrorMessage } from "@agricircle/shared/redux";
import { useAsyncApi } from "@agricircle/shared/hooks";

export const useSamplingApi = () => {
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// get organization-dependent measurement info
    const getMeasurementsInfo = async (callback) => {
        const result = await sendRequest("GET", "datahub/soilsampling/measurements");
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get analyses info", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// Sampling kit preflight
    const samplingKitPreflight = async (farmId, fieldIds, callback) => {
        const result = await sendRequest("POST", `datahub/soilsampling/kit/${farmId}/preflight`, fieldIds);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to estimate soil sampling kit", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// Prepare soil sampling kit
    const prepareSamplingKit = async (farmId, fieldIds, measurements, depths, callback) => {
        const payload = { field_ids: fieldIds, options: { measurements, depths } };
        const result = await sendRequest("POST", `datahub/soilsampling/kit/${farmId}`, payload);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to prepare soil sampling kit", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// Regenerate soil sampling kit from latest measurements
    const regenerateSamplingKit = async (farmId, fieldIds, callback) => {
        const result = await sendRequest("POST", `datahub/soilsampling/kit/${farmId}`, { field_ids: fieldIds, options: { measurements_date: "latest" } });
        if (result.isError)
            dispatch(displayErrorMessage("Failed regenerate soil sampling kit", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// sampling kit status
    const samplingKitStatus = async (farmId, jobId, callback) => {
        const jobPath = jobId ? `/${jobId}` : "";
        const url = `datahub/soilsampling/kit/${farmId}${jobPath}`
        const result = await sendRequest("GET", url);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get sampling status", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// sampling kit status
    const cancelSamplingKitRequest = async (farmId, jobId, callback) => {
        const url = `datahub/soilsampling/kit/${farmId}/${jobId}`
        const result = await sendRequest("DELETE", url);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to cancel sampling request", result.data));
        else if (callback)
            callback();
        return result;
    };

    ////////////////// get sampling path
    const getSamplingPath = async (fieldId, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}/soil_sampling_path`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get sampling path", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// getExpected measurements
    const getExpectedMeasurements = async (callback) => {
        const result = await sendRequest("GET", "datahub/soilsampling/expected_measurements");
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get expected measurements", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    ////////////////// POST measurements
    const uploadMeasurements = async (measurements, callback) => {
        const result = await sendRequest("POST", "fields/measurements", measurements);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to upload measurements", result.data));
        else if (callback)
            callback(result.data.url);
        return result;
    };

    ////////////////// Parse measurement csv
    const parseCsvMeasurementsFile = async (fieldId, formData, callback) => {
        const result = await sendRequest("POST", `datahub/soilsampling/parse_measurements/${fieldId}`, formData);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to parse measurements file", result.data));
        else if (callback)
            callback(result.data.url);
        return result;
    };

    ////////////////// Parse measurement xlsx
    const parseXlsxMeasurementsFile = async (formData, callback) => {
        const result = await sendRequest("POST", "datahub/soilsampling/parse_measurements", formData);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to parse measurements file", result.data));
        else if (callback)
            callback(result.data);
        return result;
    };

    return { getMeasurementsInfo, samplingKitPreflight, prepareSamplingKit, regenerateSamplingKit, samplingKitStatus, cancelSamplingKitRequest, getSamplingPath, getExpectedMeasurements, uploadMeasurements, parseCsvMeasurementsFile, parseXlsxMeasurementsFile };
};
